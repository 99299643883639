import api from '@apiInstance';

export default {
  add (data) {
    return api.post('jobrequest_jobs', data);
  },
  get (id) {
    return api.fetch('jobrequest_jobs/' + id);
  },
  list (params) {
    return api.fetch('/jobrequest_jobs', { params });
  },
  update (data) {
    return api.patch('jobrequest_jobs', data);
  },
  delete (id) {
    return api.remove('jobrequest_jobs', id);
  }
};
