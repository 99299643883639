<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>View RFQ</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h2>Sevice Request Information</h2>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="3">
              <b-form-group label="Ticket No." label-for="v-ticket-no">
                {{ formData.ticketno }}
              </b-form-group>
            </b-col>
            <b-col cols="12" md="9">
              <b-form-group label="Company" label-for="v-company">
                {{ formData.companyname }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Report Date" label-for="v-reportdate-date">
                {{ formData.prev_reportdate }}
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Job Schedule Date"
                label-for="v-expectedcompletion-date"
              >
                {{ formData.prev_expectedcompletion }}
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                label="Problem Description"
                label-for="v-problemdesc"
              >
                {{ formData.problemdesc }}
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <h2>Job Site</h2>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                label="Site/Branch/Outlet Name"
                label-for="v-siteid"
              >
                {{ formData.siteid }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                label="Building/Block/House No. and Street Name"
                label-for="v-addr1"
              >
                {{ formData.addr1 }}
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                label="Unit No. and Building Name"
                label-for="v-addr2"
              >
                {{ formData.addr2 }}
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Country" label-for="v-country">
                {{ formData.country }}
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Postal Code" label-for="v-postal">
                {{ formData.postal }}
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <h2>Job(s) Information</h2>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-table
                striped
                hover
                small
                :fields="fields"
                :items="dataSource"
                responsive="sm"
              >
                <template #cell(stdprice)="data">
                  {{ moneyFormat(data.value) }}
                </template>

                <template #cell(techid)="data">
                  {{ getTech(data.value) }}
                </template>

                <template #cell(actions)="data">
                  <b-button
                    v-if="data.item.joborder_headerid == 0"
                    variant="flat-primary"
                    class="btn-icon"
                    @click="onEditJob(data)"
                  >
                    <feather-icon icon="EditIcon" size="16" />
                  </b-button>
                  <b-button
                    v-if="data.item.joborder_headerid == 0"
                    v-b-modal.delete-job-modal
                    variant="flat-danger"
                    class="btn-icon"
                    @click="onDeleteJob(data)"
                  >
                    <feather-icon icon="TrashIcon" size="16" />
                  </b-button>
                </template>

                <template #row-details="data">
                  <b-card>
                    <b-row>
                      <b-col cols="12" md="6">
                        <b-form-group label="Technician" label-for="v-techid">
                          <v-select
                            id="v-techid"
                            label="fullname"
                            v-model="formdetail.techid"
                            :options="employeeItems"
                            :reduce="(employeeItems) => +employeeItems.id"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group label="JobETA" label-for="v-jobeta">
                          <flat-pickr
                            id="v-jobeta"
                            v-model="formdetail.preview_jobeta"
                            class="form-control"
                            :config="{
                              minuteIncrement: 1,
                              enableTime: true,
                              dateFormat: 'd/m/Y h:i K',
                            }"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="9">
                        <b-form-group label="Select Job" label-for="v-job">
                          <v-select
                            id="v-job"
                            label="name"
                            v-model="formdetail.jobid"
                            :options="jobs"
                            :reduce="(jobs) => +jobs.id"
                            @option:selected="onSelectJob($event)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="3">
                        <b-form-group label="Select Job" label-for="v-jobtype">
                          <v-select
                            id="v-jobtype"
                            v-model="formdetail.jobtype"
                            :options="jobtype"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="4">
                        <b-form-group label="Standard Time" label-for="v-hrs">
                          <b-input-group>
                            <b-form-input
                              v-model.number="formdetail.hrs"
                              id="v-hrs"
                            />
                            <b-input-group-append is-text>
                              Hours
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="4">
                        <b-form-group label="Standard Time" label-for="v-mins">
                          <b-input-group>
                            <b-form-input
                              v-model.number="formdetail.mins"
                              id="v-mins"
                            />
                            <b-input-group-append is-text>
                              Minute(s)
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="4">
                        <b-form-group
                          label="Standard Price"
                          label-for="v-price"
                        >
                          <b-form-input
                            type="number"
                            v-model.number="formdetail.stdprice"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <b-button
                          @click="data.toggleDetails"
                          variant="danger"
                          class="bg-darken-4 mr-1"
                        >
                          Cancel
                        </b-button>
                        <b-button
                          @click="onSubmit(data)"
                          variant="primary"
                          class="bg-darken-4"
                        >
                          Save
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card>
                </template>
              </b-table>

              <Modal
                modalId="delete-job-modal"
                @onChange="(v) => deleteJob()"
              />
            </b-col>
          </b-row>

          <b-row v-if="canSaveCreateJobs">
            <b-col>
              <b-form-group>
                <b-button
                  size="sm"
                  v-b-modal.tab-jobs-action
                  type="button"
                  class="mr-1"
                  variant="outline-success"
                >
                  Save
                </b-button>
                <b-button
                  size="sm"
                  v-b-modal.tab-jobs-action
                  type="button"
                  variant="primary"
                >
                  Create Job(s)
                </b-button>

                <Modal
                  modalId="tab-jobs-action"
                  @onChange="(v) => onCreateJobs()"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <h2>Contact Information</h2>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Reported By" label-for="v-issuedby">
                {{ formData.issuedby }}
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Contact Person" label-for="v-poc">
                {{ formData.poc }}
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Mobile" label-for="v-mobile">
                {{ formData.mobile }}
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <h2>Other Information</h2>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group label="Instructions/Remarks" label-for="v-remarks">
                {{ formData.remarks }}
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="text-right">
              <b-button
                type="button"
                class="mr-1"
                variant="outline-danger"
                @click="returnToPage"
              >
                Back
              </b-button>
              <b-button
                v-b-modal.tab-information
                type="button"
                variant="primary"
              >
                Edit
              </b-button>

              <Modal modalId="tab-information" @onChange="(v) => onEdit()" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import jobRequestApi from '@api/jobrequest_headers';
import jobRequestJobApi from '@api/jobrequest_jobs';
import jobsApi from '@api/jobs';
import employeeApi from '@api/employees';
import { queryParameters } from '@/schema';

export default {
  name: 'ServiceRequestFullView',
  components: {},
  data: () => ({
    addModal: false,
    isDisabled: false,
    canSaveCreateJobs: false,
    dataSource: [],
    selectedData: [],
    employeeItems: [],
    jobs: [],
    formData: {},
    formdetail: {
      id: 0,
      header_id: 0,
      jobid: 0,
      jobtype: null,

      hrs: 0,
      mins: 0,
    },
    fields: [
      { label: 'Job', key: 'jobname', sortable: true },
      { label: 'Type', key: 'jobtype', sortable: true },
      { label: 'Time', key: 'preview_time', sortable: true },
      { label: 'Price', key: 'stdprice', sortable: true },
      { label: 'Technician', key: 'techid', sortable: true },
      { label: 'Job ETA', key: 'preview_jobeta' },
    ],
    jobtype: [
      'Configure',
      'Dismantle',
      'Inspect',
      'Install',
      'Renew',
      'Repair',
      'Replace',
      'Service',
      'Survey Job Type',
    ],
    jobId: 0,
  }),
  mounted() {
    this.loadEmployees();
    if (+this.$route.query.id) {
      this.loadJobRequest();
    }
  },
  methods: {
    loadJobRequest() {
      jobRequestApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data != null) {
            data.prev_updated_at = this.displayDate(data.updated_at);

            if (data.reportdate) {
              data.prev_reportdate = this.displayDate(data.reportdate);
            }

            if (data.expectedcompletion) {
              data.prev_expectedcompletion = this.displayDate(
                data.expectedcompletion
              );
            }

            if (data && data.jobs && data.jobs.data) {
              this.dataSource = data.jobs.data;

              this.dataSource.forEach((e) => {
                if (e) {
                  if (!e.jobeta) {
                    e.jobeta = data.expectedcompletion;
                  }

                  if (e.joborder_headerid < 1) {
                    this.fields.push({ label: 'Actions', key: 'actions' });
                    this.canSaveCreateJobs = true;
                  } else {
                    this.fields = this.fields.filter((x) => {
                      return x.key != 'actions';
                    });

                    this.canSaveCreateJobs = false;
                  }

                  e.preview_jobeta = this.displayDate(e.jobeta);

                  e.hrs = Math.floor(e.stdtime / 60);
                  e.mins = e.stdtime % 60 || 0;

                  if (e.hrs) {
                    e.preview_time = `${e.hrs} Hour(s)`;
                    if (e.mins) {
                      e.preview_time += ` ${e.mins} Minute(s)`;
                    }

                    return;
                  }

                  e.preview_time = `${e.mins} Minute(s)`;
                }
              });
            }

            this.formData = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadEmployees() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      employeeApi
        .list(params)
        .then(({ data }) => {
          this.employeeItems = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadJobs() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      jobsApi
        .list(params)
        .then(({ data }) => {
          this.jobs = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onEdit() {
      const url = `/pages/services-tools/service-request/manage?id=${this.$route.query.id}`;
      this.$router.push(url);
    },
    onCreateJobs() {
      this.success = true;
      const data = { id: this.formData.id, jobs: [] };
      data.jobs = this.dataSource;

      if (data.jobs && data.jobs.length > 0) {
        data.jobs.forEach((job) => {
          if (job.preview_jobeta) {
            job.jobeta = this.toUnix(this.dateTimeFormat(job.preview_jobeta));
          } else {
            job.jobeta = this.formData.expectedcompletion;
          }
        });
      }

      jobRequestApi
        .createJobs(data)
        .then(({ data }) => {
          if (data) {
            this.success = false;
            this.loadJobRequest();
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    returnToPage() {
      this.$router.push(`/pages/services-tools/service-request`);
    },
    getTech(id) {
      const user = this.employeeItems.find((x) => {
        return x.id == id;
      });

      if (user) {
        return user.fullname;
      }
    },
    onEditJob(data) {
      data.toggleDetails();
      this.loadJobs();

      if (data) {
        const item = data.item;

        item.hrs = Math.floor(item.stdtime / 60);
        item.mins = item.stdtime % 60;
        this.formdetail = Object.assign({}, item);
      }
    },
    onDeleteJob(data) {
      this.jobId = data.item.id;
    },
    deleteJob() {
      if (this.jobId) {
        jobRequestJobApi
          .delete(this.jobId)
          .then(() => {
            this.loadJobRequest();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    onSubmit(event) {
      event.toggleDetails();
      const data = this.formdetail;
      data.jobid = +data.jobid;

      const stdhrsmin = data.hrs + data.mins / 60;
      data.stdtime = Math.round(+stdhrsmin * 60);

      const toUnixPreviewJobETA = this.toUnix(
        this.dateTimeFormat(data.preview_jobeta)
      );
      if (toUnixPreviewJobETA) {
        data.jobeta = this.toUnix(this.dateTimeFormat(data.preview_jobeta));
      }

      jobRequestJobApi
        .update(data)
        .then(() => {
          this.toastConfig();
          this.loadJobRequest();

          this.closeModal();
        })
        .catch((err) => {
          this.toastConfig('', 'error')
        })
        .finally(() => {
          //
        });
    },
    onSelectJob(data) {
      this.formdetail.jobname = data.name;
      this.formdetail.jobtype = data.jobtype;
      this.formdetail.stdprice = data.stdprice;
      this.formdetail.hrs = Math.floor(data.stdtime / 60);
      this.formdetail.mins = data.stdtime % 60;
      this.formdetail = Object.assign({}, this.formdetail);
    },
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        header_id: 0,
        jobid: 0,
        jobname: null,
        status: 'O',

        hrs: 0,
        mins: 0,
      };

      this.$nextTick(() => {
        this.formData = Object.assign({}, defaultForm);
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.pin:hover {
  cursor: pointer;
}

.isDisplaying.arrow_down {
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}

.isDisplaying.arrow_up {
  transform: rotate(360deg);
  transition: transform 0.25s linear;
}
</style>
